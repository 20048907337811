import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import mapboxgl from 'mapbox-gl'

dayjs.extend(duration)

type MapStateContextValue = {
  fromDate: Date
  setFromDate: any
  toDate: Date
  setToDate: any
  tracks?: any
  setTracks: (v: any) => void
  selectedTrack: any
  activeGrooves: Array<{
    source: string
    layer: string
    label: string
    color: string
  }>
  showActiveGrooves: boolean
  setShowActiveGrooves: (show: boolean) => void
  setActiveGrooves: (
    grooves: Array<{
      source: string
      layer: string
      label: string
      color: string
    }>,
  ) => void
  setSelectedTrack: (g: any) => void
  loadingMap: boolean
  setLoadingMap: (loading: boolean) => any
  activeMap: mapboxgl.Map | null
  setActiveMap: (map: mapboxgl.Map | null) => any
  useCleanData: boolean
  setUseCleanData: (use: boolean) => void
  useAllData: boolean
  setUseAllData: (useAll: boolean) => void
  removeTrack: (id: string) => void // DS3DeleteATrack: Función para eliminar un track
}

/**
 * Context object
 */
export const MapState = createContext<MapStateContextValue | null>(null)

/**
 * Component that will be used as provider for the entire app
 */
export const MapProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeMap, setActiveMap] = useState<mapboxgl.Map | null>(null)
  const [loadingMap, setLoadingMap] = useState(false)
  const [useAllData, setUseAllData] = useState(true)
  const [useCleanData, setUseCleanData] = useState(false)
  const [fromDate, setFromDate] = useState(dayjs().toDate())
  const [toDate, setToDate] = useState(dayjs().toDate())
  const [tracks, setTracks] = useState<any>([]) // Inicializamos como array vacío
  const [selectedTrack, setSelectedTrack] = useState<any>(null)
  const [showActiveGrooves, setShowActiveGrooves] = useState(false)
  const [activeGrooves, setActiveGrooves] = useState<
    Array<{ source: string; layer: string; label: string; color: string }>
  >([])

  // Función para eliminar un track // DS3DeleteATrack
  const removeTrack = (id: string) => {
    setTracks((prevTracks: any[]) =>
      prevTracks.filter((track) => track.id !== id),
    )
    if (selectedTrack?.id === id) {
      setSelectedTrack(null) // Limpiamos la selección si el track eliminado es el seleccionado
    }
  }

  const mapStateValue = useMemo(
    () => ({
      fromDate,
      setFromDate,
      toDate,
      setToDate,
      tracks,
      selectedTrack,
      showActiveGrooves,
      setShowActiveGrooves,
      setTracks,
      setSelectedTrack,
      activeGrooves,
      setActiveGrooves,
      loadingMap,
      setLoadingMap,
      useCleanData,
      setUseCleanData,
      useAllData,
      setUseAllData,
      activeMap,
      setActiveMap,
      removeTrack, // DS3DeleteATrack
    }),
    [
      fromDate,
      toDate,
      tracks,
      selectedTrack,
      showActiveGrooves,
      activeGrooves,
      loadingMap,
      useCleanData,
      useAllData,
      activeMap,
    ],
  )

  return <MapState.Provider value={mapStateValue}>{children}</MapState.Provider>
}

/**
 * Custom hook to access state on any child component
 */
export const useMapState = () => {
  const context = useContext(MapState)

  if (!context) {
    throw new Error('MapState must be used within MapProvider component')
  }

  return context
}
